import React, { useCallback, useRef, useState } from 'react'
import { jsPDF } from 'jspdf'

//Styles
import './DownloadFile.scss'

interface File {
  name: string
  content: string
  type: string
  size: number
}

export const DownloadFile: React.FC<File> = (input): JSX.Element => {
  const [file] = useState(input)

  const downloadPdf = useCallback(() => {
    const doc = new jsPDF('p', 'px')
    doc.addImage(file.content, file.type, 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height)
    doc.save(file.name.replace(/\.[^/.]+$/, ''), { returnPromise: true })
  }, [])

  return (
    <div className={'pdf-converter-container-download-area-wrapper'}>
      <img className={'pdf-converter-container-download-area-wrapper-img'} id={`${file.name}_${Date.now}`} src={file.content} alt={file.name} />
      {/* <div className={'pdf-converter-container-download-area-wrapper-button'}>Hent</div> */}
    </div>
  )
}
