import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { jsPDF } from 'jspdf'
import { saveAs } from 'file-saver'
import JsZip from 'jszip'

// Styles
import './PdfConverter.scss'

// Components
import { DownloadFile } from './components/DownloadFile'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderColor: '#ecf7ff',
}

const acceptStyle = {
  borderColor: '#09d6a1',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

interface UserFile {
  name: string
  content: string
  type: string
  size: number
  height: number
  width: number
}

export const PdfTool: React.FC = (): JSX.Element => {
  const [files, setFiles] = useState(new Array<UserFile>())
  const downloadZipRef = createRef<HTMLButtonElement>()
  const downloadSingleRef = createRef<HTMLButtonElement>()
  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    noClick: true,
    noKeyboard: true,
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  useEffect(() => {
    loadFiles()
  }, [acceptedFiles, acceptedFiles?.length])

  async function loadFiles() {
    let userFiles = new Array<UserFile>()
    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = await readFileAsync(acceptedFiles[i])
      userFiles.push(file)
    }
    setFiles((prevFiles) => prevFiles.concat(userFiles))
  }

  function readFileAsync(file: File) {
    return new Promise<UserFile>((resolve, reject) => {
      let reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading was failed')
      reader.onload = () => {
        var image = new Image()
        image.src = reader.result as string

        image.onload = () => {
          resolve({
            name: file.name.replace(/\.[^/.]+$/, ''),
            content: reader.result as string,
            type: file.type,
            size: file.size,
            width: image.width,
            height: image.height,
          })
        }
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  const downloadElements = files.map((file, i) => (
    <DownloadFile key={i} content={file.content} name={file.name} size={file.size} type={file.type}></DownloadFile>
  ))

  var downloadSinglePdf = useCallback(() => {
    if (files.length === 0) return
    downloadSingleRef.current?.setAttribute('disabled', 'disabled')

    setTimeout(() => {
      let firstImage = files[0]
      const doc = new jsPDF(isPortrait(firstImage), 'px', [firstImage.width, firstImage.height], true)

      //Add front page
      doc.addImage(firstImage.content, firstImage.type, 0, 0, firstImage.width, firstImage.height, 'FAST')

      for (let i = 1; i < files.length; i++) {
        const file = files[i]
        doc.addPage([file.width, file.height], isPortrait(file))
        doc.addImage(file.content, file.type, 0, 0, file.width, file.height, undefined, 'FAST')
      }
      saveAs(doc.output('blob'), `EF_${files.length}_${firstImage.name}`)
      downloadSingleRef.current?.removeAttribute('disabled')
    }, 500)
  }, [files])

  var downloadZip = useCallback(() => {
    if (files.length === 0) return
    downloadZipRef.current?.setAttribute('disabled', 'disabled')

    setTimeout(() => {
      var zip = new JsZip()

      let firstImage = files[0]
      const doc = new jsPDF(isPortrait(firstImage), 'px', [firstImage.width, firstImage.height], true)

      //Only one file no need to generate ZIP
      if (files.length === 1) {
        doc.addImage(firstImage.content, firstImage.type, 0, 0, firstImage.width, firstImage.height, 'FAST')

        saveAs(doc.output('blob'), firstImage.name)
        downloadZipRef.current?.removeAttribute('disabled')
        return
      }

      //Combine into zip for single download
      for (let i = 0; i < files.length; i++) {
        const file = files[i]

        doc.addImage(file.content, file.type, 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, undefined, 'FAST')
        zip.file(file.name + '.pdf', doc.output('blob'))
      }

      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, 'estate-family PDF.zip')
        downloadZipRef.current?.removeAttribute('disabled')
      })
    }, 500)
  }, [files])

  function isPortrait(img: UserFile) {
    return img.height > img.width ? 'p' : 'l'
  }

  return (
    <div id="PdfConverter">
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Konverter nemt til PDF på få sekunder</p>
          <button className="pdf-converter-container-button" type="button" onClick={open}>
            VÆLG FIL
          </button>
          <p>Træk og slip filen her</p>
        </div>
        <aside>
          <div className="pdf-converter-container-download-area">{downloadElements}</div>
        </aside>
      </div>
      <div className="pdf-converter-container-download-button">
        <button ref={downloadZipRef} className="pdf-converter-container-download-button-all" type="button" onClick={downloadZip}>
          Download
        </button>
        <button ref={downloadSingleRef} className="pdf-converter-container-download-button-single" onClick={downloadSinglePdf}>
          Download én samlet PDF
        </button>
      </div>
    </div>
  )
}
