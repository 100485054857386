import React from 'react'

// Components
import { PdfTool } from './components/PdfConverter'

function App() {
  return (
    <div className="App">
      <PdfTool />
    </div>
  )
}

export default App
